import { RouteObject } from 'react-router';
import { Link } from 'react-router-dom';
import { TextField } from '@ff-it/form';
import type { Group } from 'modules/core/types';
import { ModelScene, EntityScene, UpdateScene, DeleteScene, CreateScene, NewIndexScene } from 'components';
import { Permissions } from './Permissions';
import permissions from 'modules/core/groups/permissions';
import { ReactElement } from 'react';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<Group> = [
  {
    content: 'Name',
    key: 'name',
    width: undefined,
    render: ({ id, name }: Group): ReactElement => <Link to={`${id}`}>{name}</Link>,
  },
];

function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="name" label="Name" required className="col-md-6" />
      </div>
    </div>
  );
}

const model = {
  endpoint: 'auth/groups/',
  title: 'Agency role',
  pluralTitle: 'Agency roles',
  entityTitle: (e: any) => e.name,
  permissions,
};

export const route: RouteObject = {
  path: 'agency_roles',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <NewIndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <>
              <UpdateScene viewDisabled>
                <Form />
              </UpdateScene>
              <Permissions />
            </>
          ),
        },

        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
