import type { ReactElement } from 'react';
import { CreateScene, DeleteScene, EntityScene, ModelScene, NewIndexScene, UpdateScene } from 'components';
import { TextField } from '@ff-it/form';
import { Link } from 'react-router-dom';
import permissions from 'modules/core/bank/permissions';
import { NewListExport } from 'components/ListActions';
import type { Bank } from 'modules/crm/types';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<Bank> = [
  {
    content: 'Title',
    key: 'title',
    render: ({ id, title }: Bank) => <Link to={`${id}`}>{title}</Link>,
  },
  {
    content: 'Code',
    key: 'code',
  },
];

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-6" required />
        <TextField name="code" label="SWIFT Code" className="col-md-6" required />
      </div>
    </div>
  );
}

const initialValues = {
  code: '',
  title: '',
};

const model = {
  endpoint: 'core/banks/',
  title: 'Bank',
  pluralTitle: 'Banks',
  entityTitle: ({ title }: Bank) => title,
  permissions,
};

export const route = {
  path: 'banks',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <NewIndexScene columns={columns} toolbar={<NewListExport permission={permissions.export} />} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
