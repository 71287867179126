import { ReactNode, useRef, type ReactElement } from 'react';
import type { ControlledTableProps, FetcherState, RowType, TableController, TablePageProps } from './types';
import { serializeQs, useQueryState } from './useQueryState';
import { TablePage } from './TablePage';
import { Navigate, useLocation } from 'react-router';
import { setStoredState, getStoredState } from './storage';
import equal from 'fast-deep-equal';
import { Box, DataTable, Pagination } from '@ff-it/ui';

const defaultPageSizes = [15, 30, 60];
const defaultDefualtFilter = {};

export function ControlledTable<R extends RowType>(props: ControlledTableProps<R>): ReactElement {
  const {
    pageSize: constantPageSize,
    defaultPageSize = 15,
    defaultFilter = defaultDefualtFilter,
    storageKey,
    queryParams,
    tableProps,
    ...rest
  } = props;

  const initialState: FetcherState = {
    pageIndex: 1,
    pageSize: constantPageSize || defaultPageSize,
    filter: defaultFilter,
    sort: undefined,
    queryParams,
  };

  const location = useLocation();
  const restoredRef = useRef<any>(null);

  if (storageKey && restoredRef.current !== storageKey && !location.search) {
    restoredRef.current === storageKey;
    const restoredState = getStoredState(storageKey);

    if (restoredState) {
      return (
        <Navigate
          to={`${location.pathname}${serializeQs({
            ...initialState,
            ...restoredState,
            filter: {
              ...initialState.filter,
              ...restoredState?.filter,
            },
          })}`}
          replace={true}
        />
      );
    }
  }

  // write back to storage when state changes
  const onStateChange = storageKey
    ? (state: FetcherState) => setStoredState(storageKey, equal(state, initialState) ? undefined : state)
    : undefined;

  function renderTable({
    data,
    onPageChange,
    pageIndex,
    pageSize,
    columns,
    sort,
    onSort,
  }: TableController<R>): ReactNode {
    return (
      <DataTable<R>
        sort={sort}
        onSort={onSort}
        data={data ? data.rows : []}
        columns={columns}
        after={
          <Box marginY="sm">
            <Pagination
              current={pageIndex}
              pageSize={pageSize}
              total={data?.total || 0}
              // no pageSizeOptions if page size is constant
              pageSizeOptions={constantPageSize ? undefined : defaultPageSizes}
              onChange={onPageChange}
              size={tableProps?.size}
            />
          </Box>
        }
        {...tableProps}
      />
    );
  }

  return (
    <QueryTablePage
      initialState={initialState}
      onStateChange={onStateChange}
      defaultFilter={defaultFilter}
      renderTable={renderTable}
      {...rest}
    />
  );
}

const QueryTablePage = ({
  initialState,
  onStateChange,
  ...props
}: Omit<TablePageProps<any>, 'state' | 'setState'> & {
  initialState: FetcherState;
  onStateChange?: (state: FetcherState) => void;
}): ReactElement => {
  const [state, setState] = useQueryState(initialState, onStateChange);
  return <TablePage state={state} setState={setState} {...props} />;
};
