import { Link } from 'react-router-dom';
import { Column } from '@ff-it/grid';
import {
  AllActivityTypesField,
  CreateScene,
  DeleteScene,
  EntityScene,
  ModelScene,
  NewIndexScene,
  UpdateScene,
} from 'components';
import permissions from 'modules/supplier/department/permissions';
import { Category } from 'modules/supplier/category/types';
import { Form } from './Form';
import { TextField } from '@ff-it/form';

const columns: Column<Category>[] = [
  {
    content: 'Name',
    key: 'name',
    sortable: true,
    render: ({ id, name }: Category) => <Link to={`${id}`}>{name}</Link>,
  },
];

const initialValues = {
  name: '',
};

const model = {
  endpoint: 'supplier/categories/',
  title: 'Media category',
  pluralTitle: 'Media categories',
  entityTitle: ({ name }: Category) => name,
  permissions,
};

export const route = {
  path: 'categories',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <NewIndexScene
          columns={columns}
          filterFields={
            <>
              <TextField name="search" placeholder="Search.." size="sm" />
              <AllActivityTypesField name="type" placeholder="Type" className="ml-1" size="sm" />
            </>
          }
        />
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form create />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene>
              <Form create={false} />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
