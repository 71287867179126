import { useEffect, useCallback, ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { Form, TextField, useFormState, useForm } from '@ff-it/form';
import { useGrid } from '@ff-it/grid';
import { Button, useDebounce } from '@ff-it/ui';

import eq from 'fast-deep-equal';
import { Icon } from './ui';

export const filterCheckProps = {
  format: (v: any): any => {
    if (v === 'true') {
      return true;
    } else if (v === 'false') {
      return false;
    }
    return false;
  },
  parse: (v: any): any => {
    return String(v);
  },
};

const empty = {};

interface FilterControlProps {
  filter: any;
  setFilter: (f: any) => void;
  reset?: boolean;
  defaultFilter?: any;
}

export function FilterControl({
  filter,
  setFilter,
  reset = true,
  defaultFilter = empty,
}: FilterControlProps): ReactElement | null {
  const { initialize } = useForm();
  const { valid, values } = useFormState({ subscription: { valid: true, values: true } });
  const applyFilter = useDebounce(
    // biome-ignore lint/correctness/useExhaustiveDependencies: defaultFilter should be stable
    useCallback(
      (raw) => {
        const values = Object.keys(raw).reduce((acc: any, key) => {
          const v = raw[key];
          // FIXME: seems suboptimal, selects are reset to null
          if (v === null) {
            return acc;
          }
          if (Array.isArray(v) && v.length === 0) {
            return acc;
          }
          acc[key] = v;
          return acc;
        }, {});

        if (!eq(filter, values)) {
          const v = values && Object.keys(values).length > 0 ? values : defaultFilter;
          setFilter(v);
        }
      },
      [setFilter, filter],
    ),
    500,
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: applyFilter is in sync
  useEffect(() => {
    valid && applyFilter(values);
  }, [valid, values]);

  if (reset && !eq(values, defaultFilter)) {
    return (
      <Button variant="outline-danger" size="sm" className="ml-1  border-0" onClick={() => initialize(defaultFilter)}>
        <Icon icon="xmark" />
      </Button>
    );
  }
  return null;
}

const onSubmit = async (): Promise<void> => undefined;

interface ListFilterProps {
  className?: string;
  children?: ReactNode;
  defaultFilter?: any;
}

/** @deprecated use NewIndex */
export function ListFilter({ children, className, defaultFilter = empty }: ListFilterProps): ReactElement {
  const { filter, setQueryState } = useGrid();
  const currentFilter = filter || defaultFilter;

  return (
    <Form onSubmit={onSubmit} initialValues={currentFilter} className={cx('form-inline list-filter', className)}>
      {children ? children : <TextField name="search" placeholder="Search..." size="sm" />}
      <FilterControl
        defaultFilter={defaultFilter}
        filter={currentFilter}
        setFilter={(filter) => {
          setQueryState((state) => ({ ...state, filter, page: 1 }));
        }}
      />
    </Form>
  );
}
