import { ReactElement, ReactNode } from 'react';
import { ControlledTableProps, KeyGetter, RowType } from './types';
import { ControlledTable } from './ControlledTable';
import { Box, TableColumns } from '@ff-it/ui';
import { TableFilter } from './TableFilter';

export type IndexTableProps<R extends RowType> = {
  url: string;
  filterFields?: ReactNode;
  columns: TableColumns<R>;
  before?: ReactNode;
  storageKey?: string;
} & Pick<ControlledTableProps<R>, 'defaultFilter' | 'queryParams'>;

export function IndexTable<R extends RowType>({
  url,
  filterFields,
  before,
  storageKey = url,
  ...rest
}: IndexTableProps<R>): ReactElement {
  const getKey: KeyGetter = ({ filter, pageSize, pageIndex, sort, queryParams }) => {
    return {
      url,
      method: 'GET',
      queryParams: {
        // FIXME
        ...filter,
        page_size: pageSize,
        page: pageIndex,
        ordering: sort ? `${sort.direction === 'DESC' ? '-' : ''}${sort.key.replace('.', '__')}` : undefined,
        ...queryParams,
      },
    };
  };

  return (
    <ControlledTable<R>
      defaultPageSize={30}
      getKey={getKey}
      storageKey={storageKey}
      tableProps={{
        fontSize: 'sm',
        fixed: true,
        before: (
          <>
            {before}
            <Box display="flex" marginTop="sm">
              <TableFilter>{filterFields}</TableFilter>
            </Box>
          </>
        ),
      }}
      {...rest}
    />
  );
}
