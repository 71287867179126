import { Link } from 'react-router-dom';
import { Column } from '@ff-it/grid';
import { CreateScene, DeleteScene, EntityScene, ModelScene, NewIndexScene, UpdateScene } from 'components';
import permissions from 'modules/supplier/department/permissions';
import type { EmbeddedDepartmentSet } from 'modules/supplier/department/types';
import { Nav } from './Nav';
import { Form } from './Form';
import { PriceLists } from './PriceLists';

const columns: Column<EmbeddedDepartmentSet>[] = [
  {
    content: 'Name',
    key: 'name',
    render: ({ id, name }: EmbeddedDepartmentSet) => <Link to={`${id}`}>{name}</Link>,
  },
];

const initialValues = {
  name: '',
};

const model = {
  endpoint: 'supplier/department_sets/',
  title: 'Department set',
  pluralTitle: 'Department sets',
  entityTitle: ({ name }: EmbeddedDepartmentSet) => name,
  permissions,
};

export const route = {
  path: 'department_sets',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <NewIndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled heading={null}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'price_lists/*',
          element: <PriceLists />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
