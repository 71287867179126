import {
  CreateScene,
  DeleteScene,
  EntityScene,
  ModelScene,
  ModelSceneProps,
  NewIndexScene,
  UpdateScene,
} from 'components';
import { ReactElement } from 'react';
import { useRoutes } from 'react-router';
import { Person } from '../types';
import { Form } from './Form';
import { Link } from 'react-router-dom';
import { TableColumns } from '@ff-it/ui';

const columns: TableColumns<Person> = [
  {
    content: 'Name',
    key: 'first_name',
    width: 180,
    render: ({ id, first_name, last_name }) => (
      <Link to={`${id}`}>
        {first_name} {last_name}
      </Link>
    ),
  },
  {
    content: 'Position',
    key: 'position',
  },
  {
    content: 'Email',
    key: 'email',
  },
  {
    content: 'Mobile phone',
    key: 'mobile_phone',
  },
  {
    content: 'Work phone',
    key: 'work_phone',
  },
];

const initialValues = {};

export function CompanyPersons(model: ModelSceneProps<Person>): ReactElement {
  const routes = useRoutes([
    {
      index: true,
      element: <NewIndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return <ModelScene {...model}>{routes}</ModelScene>;
}
