import { Column } from '@ff-it/grid';
import { date, state, timestamp } from 'components/columns';
import { EnterpriseLink, Period } from 'components';
import { BookingStateBadge, PaymentStateBadge, InvoiceSource } from 'modules/invoicing/common';
import { fmtMonth, sepFormat } from 'utilities';
import type { ListIncome } from './types';
import { CompanyTitle } from 'modules/core/company/components';
import { IsLinkedStateBadge } from '../common/IsLinkedStateBadge';
import { DocumentApplicationStateBadge } from '../common/DocumentApplicationStateBadge';
import { LinkApplicationStateBadge } from '../common/LinkApplicationStateBadge';

const sumProps = {
  width: 120,
  className: 'text-right',
  headerClassName: 'text-right',
  sortable: true,
};

export const columns: Column<ListIncome>[] = [
  {
    content: 'Number',
    key: 'number',
    // fixme
    sortable: false,
    width: 150,
    // absolute link since we use it in nested lists
    render: ({ id, number }: ListIncome) => <EnterpriseLink to={`/invoices/income/${id}`}>{number}</EnterpriseLink>,
  },

  state<ListIncome>(),
  { content: 'Type', key: 'type', width: 120, sortable: true },
  {
    key: '_',
    render: (i) => <InvoiceSource {...i} />,
    className: 'px-0 text-center',
    width: 30,
  },
  {
    className: 'pl-0 py-1',
    headerClassName: 'pl-0',
    content: 'Payer',
    key: 'payer.title' as any,
    render: ({ payer }) => <CompanyTitle {...payer} />,
    width: undefined,
    sortable: true,
  },
  {
    className: 'py-0',
    content: 'Service per.',
    key: 'date_from',
    render: ({ date_from, date_to }) => (date_from ? <Period date_from={date_from} date_to={date_to} /> : null),
    width: 120,
  },
  {
    className: 'py-0',
    content: 'Finance per.',
    key: 'finance_period',
    render: ({ finance_period }) =>
      finance_period ? <Period date_from={finance_period.start} date_to={finance_period.end} fmt={fmtMonth} /> : null,
    width: 120,
  },
  timestamp('Created', 'created_at', { sortable: true }),
  date('Date', 'checkout_date', { sortable: true }),
  date('P.date', 'payment_date', { sortable: true }),

  { content: 'Sub total', key: 'sub_total', render: ({ sub_total }) => sepFormat(sub_total), ...sumProps },
  { content: 'Total', key: 'total', render: ({ total }) => sepFormat(total), ...sumProps },
  {
    width: '120px',
    className: 'px-0',
    key: 'sums',
    render: (invoice: ListIncome) => (
      <>
        <PaymentStateBadge state={invoice.sums.paym_state} />
        <BookingStateBadge state={invoice.state} booking_state={invoice.booking_state} />
        <IsLinkedStateBadge linked_at={invoice.linked_at} link_state={invoice.sums.link_state} />
        <LinkApplicationStateBadge direction={invoice.direction} link_state={invoice.sums.link_state} />
        <DocumentApplicationStateBadge invoice={invoice} />
      </>
    ),
  },
];
