import { RouteObject } from 'react-router';
import { Form } from './Form';
import { ModelScene, CreateScene, UpdateScene, EntityScene, DeleteScene, EntityNav, NewIndexScene } from 'components';
import type { CVB, ListBonus } from 'modules/guarantee/types';

import { columns, initialValues } from 'modules/guarantee/common/bonus';
import permissions from 'modules/guarantee/cvb/permissions';
import { Expense } from './Expense';
import { Filter } from 'modules/guarantee';

const model = {
  endpoint: 'guarantee/cvb/',
  title: 'CVB',
  pluralTitle: 'CVB',
  entityTitle: (g: ListBonus) => g.number,
  permissions,
};

function copy(cvb: CVB): any {
  return {
    ...cvb,
    id: undefined,
    date_from: null,
    date_to: null,
    number: '',
    attachments: [],
    rates: cvb.rates.map((rate) => ({ ...rate, id: undefined })),
  };
}

export const route: RouteObject = {
  path: 'cvb',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <NewIndexScene columns={columns} storageKey="guarantee_cvb" filterFields={<Filter />} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <EntityNav
            nav={[
              {
                to: '',
                end: true,
                children: 'Details',
              },
              {
                to: 'expense',
                children: 'Expense',
                perm: permissions.invoice,
              },
            ]}
          />
        </EntityScene>
      ),

      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled copy={copy}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
        {
          path: 'expense/*',
          element: <Expense />,
        },
      ],
    },
  ],
};
