import { DatePickerField, SelectField, TextField } from '@ff-it/form';
import {
  ModelScene,
  IndexScene,
  DeleteScene,
  UpdateScene,
  EntityScene,
  CreateScene,
  ListFilter,
  InvoiceStateField,
  useEntity,
  EntityFormActions,
  withHasPerm,
} from 'components';
import { Form } from './Form';
import { Nav } from './Nav';
import { all, useHasPerms } from 'core/permissions';
import { actions, columns } from 'modules/invoicing/expenses';
import permissions from 'modules/invoicing/expenses/permissions';
import { History, StateFilters } from 'modules/invoicing/common';
import { useAgencyVAT } from 'modules/core/useAgencyVAT';
import { SynchronizationConflictsAlert } from 'modules/invoicing/expenses/SynchronizationConflictsAlert';
import { route as conflictsRoute } from './Conflicts';
import { AuthState } from 'services';
import { ReactElement } from 'react';
import { Expense } from 'modules/invoicing/expenses/types';
import { CompanyField } from 'modules/core/company/components';
import { ExpenseLinker } from 'modules/linker';
import { invoiceTypeOptions } from 'modules/invoicing/common/options';
import { ListExport } from 'components/ListActions';
import { Applicator } from 'modules/applicator/Applicator';

const ProtectedExpenseLinker = withHasPerm(ExpenseLinker, permissions.link);
const ProtectedApplicator = withHasPerm(Applicator, permissions.change);

const model = {
  endpoint: 'invoicing/expense/',
  title: 'Expense',
  pluralTitle: 'Expenses',
  entityTitle: (e: Expense) => e.number,
  permissions,
};

function Create(): ReactElement {
  const vat_rate = useAgencyVAT();
  const initialValues = {
    // display_number: string;
    state: 'DRAFT' as const,

    checkout_date: null,
    payment_date: null,
    comment: '',
    type: 'INVOICE' as const,
    alt_currency: null,
    alt_amount: null,
    alt_total: null,
    vat_rate,
    // sub_total: string;
    vat_amount: null,
    total: null,
    attachments: [],
    clients: [],
    finance_period: null,
  };

  return (
    <CreateScene initialValues={initialValues}>
      <Form />
    </CreateScene>
  );
}

function InvoiceUpdateScene(): ReactElement {
  const { item } = useEntity();
  const isDraft = item.state === 'DRAFT';
  const [canChange] = useHasPerms(permissions.change);

  const disabled = !canChange || !isDraft;
  return (
    <UpdateScene
      heading={null}
      actions={<EntityFormActions actions={actions} className="ml-auto" />}
      disabled={disabled}
      perm={permissions.view}
    >
      <Form update external={item.external} />
    </UpdateScene>
  );
}

const sourceOptions = [
  { value: 'CAMPAIGN', label: 'Campaign' },
  { value: 'CVB', label: 'CVB' },
];

const no_horizon = (s: AuthState): boolean => s.scope?.accounting_integration !== 'horizon';

export const route = {
  path: 'expense',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          canCreate={all(no_horizon, permissions.add)}
          columns={columns}
          toolbar={<ListExport permission={permissions.export} />}
          storageKey="invoices_expense"
        >
          <SynchronizationConflictsAlert />
          <ListFilter>
            <TextField name="search" placeholder="Search.." size="sm" />
            <SelectField name="source" options={sourceOptions} simple placeholder="Source" className="ml-1" size="sm" />
            <SelectField
              name="type"
              options={invoiceTypeOptions}
              simple
              placeholder="Type"
              className="ml-1"
              size="sm"
            />
            <CompanyField
              name="partner"
              placeholder="Provider"
              className="ml-1"
              simple
              size="sm"
              includeBranches={true}
            />
            <CompanyField name="client" placeholder="Client" className="ml-1" simple size="sm" includeBranches={true} />
            <InvoiceStateField name="state" placeholder="State" className="ml-1" simple size="sm" />
            <DatePickerField name="date_from" placeholder="Date from" className="ml-1" size="sm" />
            <DatePickerField name="date_to" placeholder="Date to" className="ml-1" size="sm" />
            <StateFilters />
          </ListFilter>
        </IndexScene>
      ),
    },
    conflictsRoute,
    {
      path: 'create',
      element: <Create />,
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: <InvoiceUpdateScene />,
        },
        {
          path: 'link',
          element: <ProtectedExpenseLinker />,
        },
        {
          path: 'application',
          element: <ProtectedApplicator />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
        {
          path: 'history',
          element: <History />,
        },
      ],
    },
  ],
};
