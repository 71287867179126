import { ReactElement } from 'react';
import { Link, Navigate, useRoutes } from 'react-router-dom';
import { useEntity, IndexContainer, Heading, Icon, withHasPerm, TabNav, SubNavItem } from 'components';
import { columns } from 'routes/Agency/Invoices/Income';
import permissions from 'modules/invoicing/income/permissions';
import { CampaignInvoiceBuilder, CampaignInvoiceCorrector } from 'modules/linker';
import { InvoiceFooter } from '../InvoiceFooter';
import { ListPageHandler } from 'utilities';
import { Button } from '@ff-it/ui';
import type { CampaignDetails } from 'modules/campaign/campaign/types';

const cols = [
  ...columns.slice(0, -1),
  {
    content: 'Bound',
    key: 'campaign_bound',
    width: 120,
    className: 'text-right',
    headerClassName: 'text-right',
  },
  ...columns.slice(-1),
];

const nav: SubNavItem[] = [
  {
    to: 'campaign',
    children: 'Campaign',
  },
  {
    to: 'avb',
    children: 'AVB',
  },
];

export const Income = withHasPerm(function Income(): ReactElement {
  const { item } = useEntity<CampaignDetails>();
  const routes = useRoutes([
    {
      path: 'campaign',
      children: [
        {
          index: true,
          element: (
            <IndexContainer
              url={`invoicing/income/`}
              pageHandler={ListPageHandler}
              pageSizes={null}
              columns={cols}
              defaultPageSize={Number.POSITIVE_INFINITY}
              filter={{
                campaign: item.id,
                source: 'CAMPAIGN',
              }}
              after={<InvoiceFooter campaingTotal={item.sums.income_total} />}
            >
              <Heading>
                <h4 className="mb-0">Net invoices</h4>
                <div className="btn-toolbar ml-auto">
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    to="correct"
                    component={Link}
                    className="mr-1"
                    testId="correct-overspent"
                  >
                    Correct overspent <Icon className="ml-1" icon="wrench" />
                  </Button>
                  <Button variant="outline-primary" size="sm" to="create" component={Link}>
                    Create Income <Icon className="ml-1" icon="circle-plus" />
                  </Button>
                </div>
              </Heading>
            </IndexContainer>
          ),
        },
        {
          // @TODO perm?
          path: 'create',
          element: <CampaignInvoiceBuilder />,
        },
        {
          // @TODO perm?
          path: 'correct',
          element: <CampaignInvoiceCorrector />,
        },
      ],
    },
    {
      path: 'avb',
      element: (
        <IndexContainer
          url={`invoicing/income/`}
          pageHandler={ListPageHandler}
          pageSizes={null}
          columns={cols}
          defaultPageSize={Number.POSITIVE_INFINITY}
          filter={{
            campaign: item.id,
            source: 'AVB',
          }}
          after={<InvoiceFooter />}
        >
          <Heading>
            <h4 className="mb-0">Bonus invoices</h4>
          </Heading>
        </IndexContainer>
      ),
    },
    {
      index: true,
      element: <Navigate to="campaign" replace />,
    },
  ]);

  return (
    <>
      <div className="container-fluid">
        <TabNav nav={nav} />
      </div>
      {routes}
    </>
  );
}, permissions.view);
