import { Column } from '@ff-it/grid';
import { Entity } from 'types';
import { Icon, Badge } from 'components';
import { formatDate, formatDateTime } from 'utilities';
import { getIn } from 'final-form';

export function state<T extends Entity & { state: string }>(props?: Partial<Column<T>>): Column<T> {
  return {
    content: 'State',
    width: 120,
    key: 'state',
    render: ({ state }) => <Badge state={state} />,
    ...props,
  };
}

export function bool<T extends Entity>(
  Header: string,
  key: Extract<keyof T, string>,
  props?: Partial<Column<T>>,
): Column<T> {
  return {
    content: Header,
    key,
    headerClassName: 'text-center',
    className: 'text-center',
    render: (e) =>
      e[key] === null ? null : (
        <Icon className={e[key] ? 'text-success' : 'text-danger'} icon={e[key] ? 'check' : 'xmark'} />
      ),
    width: 120,
    ...props,
  };
}

export function date<T extends Entity>(
  Header: string,
  key: Extract<keyof T, string | null> | string,
  props?: Partial<Column<T>>,
): Column<T> {
  return {
    content: Header,
    key,
    headerClassName: 'text-left',
    className: 'text-left',
    width: '115px',
    render: (e) => {
      const v = getIn(e, key);

      return e && v ? (formatDate(v) as any) : null;
    },
    ...props,
  };
}

export function dateFrom(props?: Partial<Column<any>>): Column<any> {
  return date('Date from', 'date_from' as any, {
    width: '115px',
    ...props,
  });
}

export function dateTo(props?: Partial<Column<any>>): Column<any> {
  return date('Date to', 'date_to' as any, {
    width: '115px',
    ...props,
  });
}

export function timestamp<T extends Entity>(
  Header: string,
  key: Extract<keyof T, string>,
  props?: Partial<Column<T>>,
): Column<T> {
  return {
    content: Header,
    key,
    headerClassName: 'text-left',
    className: 'text-left',
    width: '135px',
    render: (e) => (e[key] ? formatDateTime(e[key] as any) : null),
    ...props,
  };
}
