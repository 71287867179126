import { ReactElement } from 'react';
import { useEntity, ModelScene, NewIndexScene, CreateScene, EntityScene, UpdateScene, DeleteScene } from 'components';
import type { Enterprise } from 'modules/core/types';
import { RouteObject, useRoutes } from 'react-router';
import { columns, Form } from 'modules/core/membership';

const initialValues = {
  user: null,
  groups: [],
};

export function Membership(): ReactElement | null {
  const { endpoint: entityEndpoint } = useEntity<Enterprise>();
  const model = {
    endpoint: `${entityEndpoint}membership/`,
    title: 'Membership',
    pluralTitle: 'Membership',
    entityTitle: ({ user }: any) => user.email,
  };

  const routes: RouteObject[] = [
    {
      path: '', // index can't have children apparently
      element: <ModelScene {...model} />,
      children: [
        {
          index: true,
          element: <NewIndexScene columns={columns} />,
        },
        {
          path: 'create',
          element: (
            <CreateScene initialValues={initialValues}>
              <Form />
            </CreateScene>
          ),
        },
        {
          path: ':id/',
          element: <EntityScene />,
          children: [
            {
              index: true,
              element: (
                <UpdateScene>
                  <Form />
                </UpdateScene>
              ),
            },
            {
              path: 'delete',
              element: <DeleteScene />,
            },
          ],
        },
      ],
    },
  ];

  return useRoutes(routes);
}
