import { ReactElement } from 'react';
import {
  ModelScene,
  IndexScene,
  DeleteScene,
  UpdateScene,
  EntityScene,
  CreateScene,
  ListFilter,
  useEntity,
  InvoiceStateField,
  EntityFormActions,
  UserField,
  withHasPerm,
} from 'components';
import { Form } from './Form';
import { Nav } from './Nav';
import { actions, columns } from 'modules/invoicing/income';
import permissions from 'modules/invoicing/income/permissions';
import { DatePickerField, SelectField, TextField } from '@ff-it/form';
import { IncomeLinker } from 'modules/linker';
import { History, StateFilters } from 'modules/invoicing/common';
import type { Income } from 'modules/invoicing/income/types';
import { CompanyField } from 'modules/core/company/components';
import { invoiceTypeOptions } from 'modules/invoicing/common/options';
import { useInitialValues } from 'modules/invoicing/income/useInitialValues';
import { useLocation } from 'react-router';
import { ListExport } from 'components/ListActions';
import { Applicator } from 'modules/applicator/Applicator';
export { columns };

const ProtectedIncomeLinker = withHasPerm(IncomeLinker, permissions.link);
const ProtectedApplicator = withHasPerm(Applicator, permissions.change);

function CreateInvoiceScene(): ReactElement {
  const initialValues = useInitialValues(useLocation().state?.creditSource);

  return (
    <CreateScene initialValues={initialValues}>
      <Form />
    </CreateScene>
  );
}

const model = {
  endpoint: 'invoicing/income/',
  title: 'Income',
  pluralTitle: 'Income',
  entityTitle: (e: Income) => e.number,
  permissions,
};

function InvoiceUpdateScene(): ReactElement {
  const { item } = useEntity();
  const isDraft = item.state === 'DRAFT';
  return (
    <UpdateScene
      heading={null}
      actions={<EntityFormActions actions={actions} className="ml-auto" />}
      disabled={!isDraft}
    >
      <Form update />
    </UpdateScene>
  );
}

const sourceOptions = [
  { value: 'CAMPAIGN', label: 'Campaign' },
  { value: 'AVB', label: 'AVB' },
];

export const route = {
  path: 'income',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          toolbar={<ListExport permission="invoicing.export_income" />}
          storageKey="invoices_income"
        >
          <ListFilter>
            <TextField name="search" placeholder="Search.." size="sm" />
            <SelectField name="source" options={sourceOptions} simple placeholder="Source" className="ml-1" size="sm" />
            <SelectField
              name="type"
              options={invoiceTypeOptions}
              simple
              placeholder="Type"
              className="ml-1"
              size="sm"
            />
            <InvoiceStateField name="state" placeholder="State" className="ml-1" simple size="sm" />
            <CompanyField name="payer" placeholder="Payer" className="ml-1" simple size="sm" includeBranches={true} />
            <DatePickerField name="date_from" placeholder="Date from" className="ml-1" size="sm" />
            <DatePickerField name="date_to" placeholder="Date to" className="ml-1" size="sm" />
            <UserField name="issued_by" placeholder="Issuer" className="ml-1" size="sm" simple />
            <StateFilters />
          </ListFilter>
        </IndexScene>
      ),
    },
    {
      path: 'create',
      element: <CreateInvoiceScene />,
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: <InvoiceUpdateScene />,
        },
        {
          path: 'link',
          element: <ProtectedIncomeLinker />,
        },
        {
          path: 'application',
          element: <ProtectedApplicator />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
        {
          path: 'history',
          element: <History />,
        },
      ],
    },
  ],
};
