import { CreateScene, DeleteScene, EntityScene, ModelScene, NewIndexScene, UpdateScene } from 'components';
import type { ExternalSums } from 'modules/guarantee/types';
import { columns } from './columns';
import { Form } from './Form';

const initialValues = {
  date_from: null,
  date_to: null,
  agency: null,
  comment: '',
  sums: [],
};

function copy(i: ExternalSums): any {
  return {
    ...i,
    id: undefined,
    number: null,
    date_from: null,
    date_to: null,
    sums: i.sums.map((s) => ({ ...s, id: undefined, agency_amount: null })),
    // map sump
  };
}

const model = {
  endpoint: 'guarantee/external_sum/',
  permissions: undefined,
  title: 'External sums',
  pluralTitle: 'External sums',
  entityTitle: (g: ExternalSums) => g.number,
};

export const route = {
  path: 'external_sums',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <NewIndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene copy={copy}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};
